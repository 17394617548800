import React from 'react';
import Fuse from 'fuse.js';
import styled from 'styled-components';

import { PdfSystemIcon, DocSystemIcon } from '@nib/icons';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import Section from '@nib-components/section';
import { Stack, Inline, Box } from '@nib/layout';

import Accordion from '@nib-components/accordion';
import { colorTrueGreen } from '@nib-components/theme';

import {
  ProductShape,
  SearchCriteria,
  SearchResultsSectionProps,
  ResultsListShape,
} from '../../constructs/models';

import { purchaseMethodOptions } from './search-form';

const filterProducts = (products: ProductShape[], searchCriteria): ProductShape[] => {
  if (searchCriteria.purchaseMethod === 'none') delete searchCriteria.purchaseMethod;
  if (!searchCriteria.planName && !searchCriteria.purchaseYear && !searchCriteria.purchaseMethod) {
    return [];
  }

  const filteringByName = !!searchCriteria.planName;
  if (filteringByName) {
    // Filter on Plan Name
    const fuse = new Fuse<ProductShape>(products, { threshold: 0.2, keys: ['node.planName'] });
    return fuse.search(searchCriteria.planName as string).map(({ item }) => item);
  }

  let results = products;

  if (searchCriteria.purchaseMethod) {
    // Filter on Purchase Method
    const onPurchaseMethod = (purchaseMethod) => (product) => {
      const purchaseMethodMap = {
        nibOnline: 'Online or over the phone with nib',
        nibPhone: 'Online or over the phone with nib',
        financialAdviser: 'Independent adviser',
        employer: 'Employer',
      };
      return purchaseMethodMap[purchaseMethod] == product?.node?.methodOfPurchase;
    };
    results = results.filter(onPurchaseMethod(searchCriteria.purchaseMethod));
  }

  if (results?.length && searchCriteria.purchaseYear) {
    // Filter on Purchase Year
    const onPurchaseYear =
      (purchaseYear) =>
      ({ node }) => {
        const product = node;
        const launchYear = product.launchYear && parseInt(product?.launchYear);
        const closedYear = product.closedYear && parseInt(product?.closedYear);
        const legacyLaunchYear = product.legacyLaunchYear && parseInt(product?.legacyLaunchYear);
        const legacyClosedYear = product.legacyClosedYear && parseInt(product?.legacyClosedYear);
        const isBetweenYears = (year) => (yearOne, yearTwo) =>
          year && yearOne && yearTwo && year >= yearOne && year <= yearTwo;
        const isGreater = (yearOne, yearTwo) => yearOne && yearTwo && yearOne >= yearTwo;

        return (
          (closedYear
            ? isBetweenYears(purchaseYear)(launchYear, closedYear)
            : isGreater(purchaseYear, launchYear)) ||
          (legacyClosedYear
            ? isBetweenYears(purchaseYear)(legacyLaunchYear, legacyClosedYear)
            : isGreater(purchaseYear, legacyLaunchYear))
        );
      };
    results = results.filter(onPurchaseYear(parseInt(searchCriteria.purchaseYear)));
  }

  return results;
};

interface SearchHeadingProps {
  isResults: boolean;
  searchCriteria: SearchCriteria;
}
const SearchHeading = ({ isResults, searchCriteria }: SearchHeadingProps): JSX.Element => {
  const searchCriteriaText = searchCriteria.planName
    ? searchCriteria.planName
    : (searchCriteria.purchaseYear || '') +
      (searchCriteria.purchaseYear && searchCriteria.purchaseMethod ? ' | ' : '') +
      (purchaseMethodOptions[searchCriteria.purchaseMethod || ''] || '');
  const noResultsText = `Sorry, we couldn\'t find any results for "${searchCriteriaText}"`;
  return (
    <Heading size={3} color="trueGreen">
      {isResults ? searchCriteriaText && `"${searchCriteriaText}"` : noResultsText}
    </Heading>
  );
};

const StyledAccordianItem = styled(Accordion.Item)`
  h4 {
    color: ${colorTrueGreen};
  }
`;

const ResultsList = ({ results }: ResultsListShape): JSX.Element => {
  const byNumberOfMembers = (x, y) =>
    (y?.node?.numberOfMembers || 1) - (x?.node?.numberOfMembers || 1);
  return (
    <Accordion>
      {results.sort(byNumberOfMembers).map((result) => (
        <StyledAccordianItem key={result?.node?.planName} title={result?.node?.planName}>
          <Stack space={4}>
            {result?.node?.documents.map((document) => (
              <Inline key={document?.file?.fileName} space={4}>
                {document?.file?.fileName?.endsWith('.pdf') ? (
                  <PdfSystemIcon fill="trueGreen" />
                ) : (
                  <DocSystemIcon fill="trueGreen" />
                )}
                <Link href={document?.file?.url} color="trueGreen">
                  {document?.file?.fileName}
                </Link>
              </Inline>
            ))}
          </Stack>
        </StyledAccordianItem>
      ))}
    </Accordion>
  );
};

const WrappedSection = ({ className, children, ...rest }) => (
  <Section {...rest} className={className}>
    {children}
  </Section>
);

const StyledSection = styled(WrappedSection)`
  padding: 1rem;
`;

const SearchResultsSection = ({
  products,
  searchCriteria,
  formTouched,
}: SearchResultsSectionProps): JSX.Element => {
  const results = filterProducts(products, searchCriteria);
  const isResults = results?.length > 0;
  return (
    <>
      {formTouched && (
        <StyledSection role="section">
          <Box marginBottom={4}>
            {<SearchHeading isResults={isResults} searchCriteria={searchCriteria} />}
          </Box>
          {isResults && <ResultsList results={results} />}
        </StyledSection>
      )}
    </>
  );
};

export default SearchResultsSection;
