import React from 'react';
import { Formik } from 'formik';
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';

import Copy from '@nib-components/copy';
import Heading from '@nib-components/heading';
import { Stack, Box, Column, Columns, Section } from '@nib/layout';
import Form from '@nib-components/form';
import FormControls from '@nib-components/form-control';
import Textbox from '@nib-components/textbox';
import Select from '@nib-components/select';
import { PrimaryButton } from '@nib-components/button';
import { PurchaseMethodOptions, SearchSectionProps } from '../../constructs/models';
import { colorWarmWhite60 } from '@nib-components/theme';

export const purchaseMethodOptions: PurchaseMethodOptions = {
  none: '',
  nibOnline: 'Online with nib',
  nibPhone: 'Over the phone with nib',
  financialAdviser: 'Through my financial adviser',
  employer: 'Through my employer',
};

export type SearchCriteria = {
  planName?: string;
  purchaseYear?: string;
  purchaseMethod?: string;
};

const FormControlClassName = ({ className, children, ...rest }) => (
  <FormControls {...rest} className={className} label="">
    {children}
  </FormControls>
);

const StyleFormControl = styled(FormControlClassName)`
  > div {
    max-width: unset;
  }
  div:nth-child(2) {
    ${breakpoint('md')`
      height: 50px;
    `};
  }
`;

const StyledSelect = styled(Select)`
  width: 100%;
`;

const StyledOrColumn = styled(Column)`
  align-self: flex-end;
`;

const handleYearChange = (setFieldValue, changeEvent) => {
  const value = changeEvent?.target?.value;
  let newValue = value.replace(/\D/, '');
  if (value.length > 4) newValue = value.slice(0, 4);
  setFieldValue('purchaseYear', newValue);
};

const FormWrapper = ({ id, name, children, ...rest }) => (
  <Form {...rest} id={id} name={name}>
    {children}
  </Form>
);

const StyledForm = styled(FormWrapper)`
  &&& {
    padding: 1rem 0 0 0;
    background-color: ${colorWarmWhite60};
  }
`;

const StyledSelectFormControl = styled(StyleFormControl)`
  span {
    margin-right: 30px;
  }
`;

const SearchForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  handleSubmit,
  setFieldValue,
  isSubmitting,
  isValid,
}): JSX.Element => (
  <StyledForm
    id="exampleForm"
    name="exampleForm"
    onSubmit={handleSubmit}
    spaceChildren={false}
    containerWidth="100%"
  >
    <Columns space={{ xs: 4, xl: 6 }} collapseBelow="md">
      <Column>
        <StyleFormControl
          id="planName"
          name="planName"
          label="Plan name"
          help="e.g. Standard Everyday Cover, Ultimate Health..."
          onChange={handleChange}
          value={values?.planName}
          error={errors.planName}
          valid={!errors.planName}
          validated={touched.planName}
        >
          <Textbox />
        </StyleFormControl>
      </Column>

      <StyledOrColumn width="content">
        <Heading size={{ sm: 4, md: 3, lg: 2 }} component="p">
          Or
        </Heading>
      </StyledOrColumn>

      <Column>
        <StyleFormControl
          id="purchaseYear"
          name="purchaseYear"
          label="Filter"
          help="What year did your cover start? e.g. 2015"
          onChange={(event) => {
            handleChange(event);
            handleYearChange(setFieldValue, event);
          }}
          value={values?.purchaseYear}
          error={errors.purchaseYear}
          valid={!errors.purchaseYear}
          validated={touched.purchaseYear}
        >
          <Textbox />
        </StyleFormControl>
      </Column>
      <Column>
        <StyledSelectFormControl
          id="purchaseMethod"
          name="purchaseMethod"
          label="&nbsp;"
          width="100%"
          help="How did you buy your health insurance plan?"
          onChange={handleChange}
          value={values?.purchaseMethod}
          error={errors.purchaseMethod}
          valid={!errors.purchaseMethod}
          validated={touched.purchaseMethod}
        >
          <StyledSelect options={purchaseMethodOptions} />
        </StyledSelectFormControl>
      </Column>
    </Columns>
    <Box marginTop={6}>
      <PrimaryButton type="submit">Search</PrimaryButton>
    </Box>
  </StyledForm>
);

const SectionWrapper = ({ className, children, ...rest }): JSX.Element => (
  <Section {...rest} className={className}>
    {children}
  </Section>
);

const StyledSection = styled(SectionWrapper)`
  ${breakpoint('lg')`
    > div {
      padding: 3rem;
    }
  `}
`;

const SearchSection = ({ setSearchCriteria, setFormTouched }: SearchSectionProps) => {
  type FormValues = {
    planName: string;
    purchaseYear: string;
    purchaseMethod: string;
  };

  type FormErrors = {
    planName?: string;
    purchaseYear?: string;
    purchaseMethod?: string;
  };

  const initialValues: FormValues = {
    planName: '',
    purchaseYear: '',
    purchaseMethod: '',
  };

  const validate = (values: FormValues): FormErrors => {
    let errors: FormErrors = {};
    if (!values.planName && !values.purchaseYear && !values.purchaseMethod)
      errors.planName = 'Please enter a search';
    return errors;
  };

  const onSubmit = (values: FormValues) => {
    setFormTouched(true);
    setSearchCriteria({
      ...values,
      planName: values?.planName?.trim(),
    });
  };

  return (
    <StyledSection role="section" background="warmWhite60">
      <Copy measure={false}>
        Find out what you’re covered for in three simple steps:
        <ol>
          <Stack space={1}>
            <li>
              Search using either the name of your health insurance plan or by filtering on the year
              your cover started and/or how you bought it
            </li>
            <li>
              Select your plan to see general information about what the plan covers, what it
              doesn’t cover and the terms and conditions that apply
            </li>
            <li>
              Check your personal documents to find out who is covered and any special conditions
              that may apply
            </li>
          </Stack>
        </ol>
      </Copy>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
        render={SearchForm}
        validateOnBlur={true}
      />
    </StyledSection>
  );
};

export default SearchSection;
