import React, { useState } from 'react';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { p, px, py } from 'styled-components-spacing';
import breakpoint from 'styled-components-breakpoint';
import { graphql } from 'gatsby';

import {
  DoctorGraphicIcon,
  OutpatientGraphicIcon,
  HospitalGraphicIcon,
  AssessmentGraphicIcon,
} from '@nib/icons';
import Copy from '@nib-components/copy';
import FeaturePanel from '@nib-components/feature-panel';
import Heading from '@nib-components/heading';
import Link from '@nib-components/link';
import { Box, Stack, Section } from '@nib/layout';
import { colorTrueGreen, colorWhite } from '@nib-components/theme';

import HeroPanel from '../components/HeroPanel';
import Layout from '../components/Layout';
import UtilityButtons from '../components/UtilityButtons';
import metrics from '../metrics';
import { urlConstants } from '../constructs/constants';
import { SearchCriteria, UtilityLinkModel, ContentfulProductNode } from '../constructs/models';
import {
  Wrapper,
  ContentWrapper,
  ImageWrapper,
  Image,
  HeroPanelCopy,
} from '../components/styledComponents';
import SearchResultsSection from '../components/CoveredFor/search-results';
import SearchSection from '../components/CoveredFor/search-form';

import herodesktop from '../img/covered-for/herodesktop.jpg';
import herodesktop2x from '../img/covered-for/herodesktop2x.jpg';
import heromobile from '../img/covered-for/heromobile.jpg';
import heromobile2x from '../img/covered-for/heromobile2x.jpg';
import herotablet from '../img/covered-for/herotablet.jpg';
import herotablet2x from '../img/covered-for/herotablet2x.jpg';

import documents from '../img/covered-for/documents.jpg';
import coverage from '../img/covered-for/coverage.jpg';

export const CoveredForPageQuery = graphql`
  query CoveredForPageQuery {
    allContentfulProduct {
      edges {
        node {
          launchYear(formatString: "yyyy")
          closedYear(formatString: "yyyy")
          legacyLaunchYear(formatString: "yyyy")
          legacyClosedYear(formatString: "yyyy")
          methodOfPurchase
          numberOfMembers
          planName
          documents {
            file {
              fileName
              url
            }
          }
        }
      }
    }
  }
`;

const WrappedFeaturePanel = ({ className, children }): JSX.Element => (
  <FeaturePanel className={className} space={1} iconFill="trueGreen" iconAccentFill="trueGreen">
    {children}
  </FeaturePanel>
);

const FeaturePanelStyled = styled(WrappedFeaturePanel)`
  background-color: ${colorTrueGreen};
  ${px(0)};
  ${py(4)};
  ${breakpoint('md')`
    * > * {
      ${p(2)}
      grid-template-columns: repeat(2,minmax(0,1fr));
    }
  `};
  ${breakpoint('xl')`
    * > * {
      ${p(2)}
      grid-template-columns: repeat(4,minmax(0,1fr));
    }
  `};
`;

const WappedFeatureStyled = ({ className, children, icon, ...rest }): JSX.Element => (
  <FeaturePanel.Feature className={className} icon={icon} {...rest}>
    {children}
  </FeaturePanel.Feature>
);

const FeatureStyled = styled(WappedFeatureStyled)`
  ${p(4)};
  > * {
    word-break: break-word;
    margin: 0;
  }
  > div:nth-child(2) {
    color: ${colorTrueGreen};
  }
  background-color: ${colorWhite};
`;

const utilityLinks: UtilityLinkModel[] = [
  {
    title: 'Find a Provider',
    subtitle: 'Need treatment?',
    url: urlConstants.findAProvider,
  },
  {
    title: 'Start your claim',
    subtitle: 'Making a claim?',
    url: '/claims',
  },
  {
    title: 'Log in',
    subtitle: 'Looking for my nib?',
    url: urlConstants.mynib,
  },
  {
    title: 'Find a programme',
    subtitle: 'Managing your health?',
    url: '/health-management-programmes',
  },
];

const title = 'What am I covered for?';
const metaDescription =
  "Finding out what you're covered for is easy with nib. It just takes 3 simple steps.";

const Sup = styled.sup`
  padding: 3px;
`;

const StyledLink = styled(Link)`
  padding: 0px;
`;

const FeatureSection = (): JSX.Element => (
  <Section role="section" background="trueGreen">
    <Box marginBottom={2}>
      <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h2" color="white">
        Understanding Hospital and Everyday health insurance
      </Heading>
    </Box>
    <Copy measure={false} color="white">
      There are two main types of health insurance plans available: Everyday, which covers
      day-to-day healthcare, and hospital, to cover the big things. These may be combined, along
      with additional options for extra cover. Below are the most common benefit types we provide.
    </Copy>
    <FeaturePanelStyled>
      <FeatureStyled icon={DoctorGraphicIcon} title="Everyday">
        This covers your day-to-day health costs, like visits to the dentist, GP or physio. With
        most policies you can claim back 60% or 80% of eligible costs for each member covered, up to
        your annual limits<Sup>1</Sup>.
      </FeatureStyled>
      <FeatureStyled icon={OutpatientGraphicIcon} title="Outpatient">
        Part of most hospital plans, this covers out of hospital health costs, like seeing a
        specialist or surgeon for a consult, or diagnostic investigations <Sup>2</Sup>. Search to
        find your Policy document or log in to{' '}
        <StyledLink href={urlConstants.mynib}>my nib</StyledLink> or the nib app to check the amount
        you can claim up to for each member covered every policy year<Sup>3</Sup>.
      </FeatureStyled>
      <FeatureStyled icon={HospitalGraphicIcon} title="Hospital">
        This covers the big health costs like surgery or cancer treatment. Search to find your
        Policy document or log in to <StyledLink href={urlConstants.mynib}>my nib</StyledLink> or
        the nib app to check the amount you can claim up to for each member covered every policy
        year<Sup>3</Sup>.
      </FeatureStyled>
      <FeatureStyled icon={AssessmentGraphicIcon} title="Options">
        There are a range of additional policy options for many hospital plans that add extra cover,
        including non-PHARMAC, specialist, or a serious condition lump sum. If your policy has
        options available, they will be listed in the Policy document.
      </FeatureStyled>
    </FeaturePanelStyled>
    <Stack space={2}>
      <Copy measure={false} color="white">
        <Sup>1</Sup>Plans vary. To find out your specific benefit limit, check your Policy document
        in{' '}
        <Link href={urlConstants.mynib} color="light">
          my nib
        </Link>
        .
      </Copy>
      <Copy measure={false} color="white">
        <Sup>2</Sup>Not all hospital plans have Outpatient cover (consultations or treatments that
        you don’t need to stay in hospital for). To find out your specific policy benefits, check
        your Policy document in{' '}
        <Link href={urlConstants.mynib} color="light">
          my nib
        </Link>
        .
      </Copy>
      <Copy measure={false} color="white">
        <Sup>3</Sup>Up to the benefit limit, subject to any excess payable and in line with your
        policy terms and Acceptance certificate.
      </Copy>
    </Stack>
  </Section>
);

const DocumentSection = (): JSX.Element => (
  <Section role="section" background="white">
    <Wrapper>
      <ContentWrapper>
        <Stack space={{ xs: 2, md: 4 }}>
          <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h3" color="trueGreen">
            Where do I find my personal documents?
          </Heading>
          <Copy>
            You can find personalised information about your cover, such as who is covered, any
            special conditions that apply and how much your policy currently costs, on your
            Acceptance or Renewal certificate.
          </Copy>
          <Copy>
            To read your Acceptance or Renewal certificate and Policy document, follow these simple
            steps:
            <ol>
              <Stack space={1}>
                <li>
                  Log in to <Link href={urlConstants.mynib}>my nib</Link>, or open the nib app
                </li>
                <li>Select "My documents" or "Policy"</li>
                <li>
                  Select the Policy document and Acceptance or Renewal certificate listed to view
                </li>
              </Stack>
            </ol>
            Have more than one policy number? Simply repeat step 3 for each policy.
          </Copy>
        </Stack>
      </ContentWrapper>
      <ImageWrapper>
        <Image src={documents} />
      </ImageWrapper>
    </Wrapper>
  </Section>
);

const CoverageSection = (): JSX.Element => (
  <Section role="section">
    <Wrapper>
      <ContentWrapper>
        <Stack space={{ xs: 2, md: 4 }}>
          <Box marginBottom={4}>
            <Heading size={{ sm: 4, md: 3, lg: 2 }} component="h3" color="trueGreen">
              Who is covered on my policy?
            </Heading>
          </Box>
          <Copy>
            Your latest Acceptance or Renewal certificate shows who is insured. You can view this in{' '}
            <Link href={urlConstants.mynib}>my nib</Link> or the nib app. The Policyowner(s) is the
            main account holder, who can add cover for a partner, dependent child, parent or
            grandchild. You can find out how to add or remove people by checking your Policy
            document or by getting in touch with us via our{' '}
            <Link href={urlConstants.contactUs}>online form</Link>.
          </Copy>
        </Stack>
      </ContentWrapper>
      <ImageWrapper>
        <Image thin src={coverage} />
      </ImageWrapper>
    </Wrapper>
  </Section>
);

interface GraphQlCoveredFor {
  allContentfulProduct: ContentfulProduct;
}

interface ContentfulProduct {
  edges: ContentfulProductEdgesShape[];
}

interface ContentfulProductEdgesShape {
  node: ContentfulProductNode;
}

interface CoveredForProps {
  data: GraphQlCoveredFor;
}

const CoveredForPage = (props: CoveredForProps): JSX.Element => {
  const [searchCriteria, setSearchCriteria] = useState<SearchCriteria>({
    planName: '',
    purchaseYear: '',
    purchaseMethod: '',
  });
  const [formTouched, setFormTouched] = useState(false);

  return (
    <Layout>
      <Helmet>
        <title>{title} | nib</title>
        <meta name="description" content={metaDescription} />
      </Helmet>
      <HeroPanel
        images={{
          desktop: herodesktop,
          desktop2x: herodesktop2x,
          mobile: heromobile,
          mobile2x: heromobile2x,
          tablet: herotablet,
          tablet2x: herotablet2x,
        }}
        title="What am I covered for?"
        variation="condensed"
      >
        <HeroPanelCopy>
          Find out what your plan includes and any exclusions you may have.
        </HeroPanelCopy>
      </HeroPanel>
      <SearchSection setSearchCriteria={setSearchCriteria} setFormTouched={setFormTouched} />
      <SearchResultsSection
        products={props?.data?.allContentfulProduct?.edges}
        searchCriteria={searchCriteria}
        formTouched={formTouched}
      />
      <DocumentSection />
      <FeatureSection />
      <CoverageSection />
      <Section role="section" background="warmWhite60" padding={4}>
        <UtilityButtons buttons={utilityLinks} flex />
      </Section>
    </Layout>
  );
};

export default metrics({ pageName: 'covered-for' })(CoveredForPage);
